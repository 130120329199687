<template>
  <card body-classes="standard-card-body" class="shadow">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('callcenter.organizer') }}</h4>
      <h4 class="card-title text-uppercase text-center" v-if="currentEvent.id">
        {{ currentEvent.startDate | datetime }}
        <span v-if="currentEvent.userId">
          {{ users[currentEvent.userId] | optional('lastname') }}
          {{ users[currentEvent.userId] | optional('firstname') }}
        </span>
        <base-button
          size="sm"
          link
          icon
          v-if="this.$moment(currentEvent.startDate).isAfter()"
          @click="targetAppointment"
        >
          <octo-icon icon="magnifying-glass"/>
        </base-button>
        <base-button size="sm" link icon @click="deleteAppointment">
          <octo-icon icon="wrong"/>
        </base-button>
      </h4>
    </div>
    <div class="d-flex justify-content-center my-3">
      <div class="d-flex align-items-center m-2" v-for="(item, index) in statusData" v-bind:key="index">
        <div class="rounded p-2" :style="{backgroundColor:item.color}"></div>
        <div class="ml-2 text-body" v-if="item.id">{{ $t('deal.status.' + item.id) }}</div>
      </div>
    </div>
    <DxScheduler

      :time-zone="schedulerOptions.timeZone"
      :cell-duration="schedulerOptions.cellDuration"
      :start-day-hour="schedulerService.getStartDayHour()"
      :end-day-hour="schedulerService.getEndDayHour()"
      :date-serialization-format="schedulerOptions.dateSerializationFormat"
      :maxAppointmentsPerCell="schedulerOptions.maxAppointmentsPerCell"
      :groups="schedulerOptions.groups"
      :scrolling="schedulerOptions.scrolling"
      :first-day-of-week="schedulerOptions.firstDayOfWeek"
      :views="schedulerOptions.views"
      :current-view="schedulerOptions.currentView"
      :current-date="currentDate"
      :data-source="dataSource"
      :editing="{
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: false,
        allowResizing: false,
        allowDragging: false,
      }"

      :min="daysInterval.min"
      :max="daysInterval.max"

      :onAppointmentAdded="(event) =>  event.cancel = true"
      :onAppointmentAdding="(event) => event.cancel = true"
      :onAppointmentClick="(event) => event.cancel = true"
      :onAppointmentContextMenu="(event) => event.cancel = true"
      :onAppointmentDblClick="(event) => event.cancel = true"
      :onAppointmentDeleted="(event) => event.cancel = true"
      :onAppointmentDeleting="(event) => event.cancel = true"
      :onAppointmentFormOpening="(event) => event.cancel = true"
      :onAppointmentRendered="(event) => event.cancel = true"
      :onOptionChanged="(event) => event.cancel = true"
      :onAppointmentUpdated="(event) =>  event.cancel = true"

      :on-content-ready="onContentReady"
      :onCellClick="cellClick"

      appointment-template="AppointmentTemplateSlot"
      ref="dxScheduler"
      v-observe-visibility="visibilityChanged"
    >

      <DxResource
        :allow-multiple="false"
        :data-source="resourcesData"
        field-expr="userId"
        label="Sale"
      />

      <DxResource
        :allow-multiple="false"
        :data-source="statusData"
        :use-color-as-default="true"
        field-expr="status"
        label="Status"
      />

      <template #AppointmentTemplateSlot="{ data }">
        <div class="position-relative">
          <div>{{ data | optional('appointmentData.text') }}</div>
          <div class="position-absolute deleteAppointment"
               @click="deleteAppointment"
               v-if="data.appointmentData.deletable">
            <octo-icon icon="cancel"/>
          </div>
          <div class="small">
            {{ data | optional('appointmentData.startDate') | time }} -
            {{ data | optional('appointmentData.endDate') | time }}
          </div>
        </div>
      </template>

    </DxScheduler>
  </card>
</template>

<script>
import {DxResource, DxScheduler} from "devextreme-vue/scheduler";
import {mapGetters} from "vuex";
import {ObserveVisibility} from 'vue-observe-visibility';
import {loadMessages, locale} from "devextreme/localization";
import itMessages from "@/pages/Deals/resources/it.json";
import Lead from "@/models/lead";
import {schedulerService} from "@/util/scheduler-service";
import Registry from "@/models/registry";
import OctoIcon from "@/components/octo-icon/OctoIcon";

export default {
  name: "DealToRecallScheduler",
  directives: {
    'observe-visibility': ObserveVisibility
  },
  components: {
    OctoIcon,
    DxScheduler,
    DxResource,
  },
  data() {
    return {
      schedulerService: schedulerService,
      schedulerOptions: schedulerService.options,
      currentDate: new Date(),
      currentTime: new Date(),
      dateFrom: this.$moment(),
      dataSource: [],
      resourcesData: [],
      statusData: schedulerService.statusData,
      scheduler: null,
      currentEvent: {},
    }
  },
  props: {
    lead: {
      type: Object,
      default: () => this.$_.cloneDeep(Lead)
    },
    registry: {
      type: Object,
      default: () => this.$_.cloneDeep(Registry)
    },
  },
  computed: {

    ...mapGetters({
      sales: 'common/sales',
      users: 'common/users',
      settings: 'common/settings',
      currentUser: 'auth/currentUser',
    }),

    daysInterval() {
      return {
        min: this.$moment().toDate(),
        max: undefined
      }
    },

  },
  created() {
    loadMessages(itMessages);
    locale('it');
  },
  async beforeMount() {
    this.resourcesData = schedulerService.resourcesData(this.sales);
    this.dataSource = await this.getData(this.dateFrom.format('YYYY-MM-DD'));
    this.setEcho();
  },

  methods: {

    getCurrentEvent() {
      return this.currentEvent;
    },

    onContentReady(e) {
      this.scheduler = e.component;
      if (this.currentTime.getDate() === this.scheduler.getStartViewDate().getDate()) {
        this.scheduler.scrollTo(this.currentTime);
      }
    },

    async getData(dateFrom) {
      try {
        const resp = await schedulerService.getData(dateFrom, null, this.$api);
        return this.$_.map(resp, (item) => {
          return this.parseDealDate(item);
        });
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        return [];
      }

    },

    parseDealDate: function (item) {
      return schedulerService.parseDealDate(item);
    },

    cellClick: function (event) {

      const startDate = this.$moment(event.cellData.startDate);
      const endDate = this.$_.cloneDeep(startDate);
      endDate.add(this.settings?.callcenter_appointment_duration || 90, 'minutes');

      if (this.currentEvent.id) {
        this.dataSource = this.$_.filter(this.dataSource, (item) => {
          return item.id !== this.currentEvent.id;
        });
      }

      this.currentEvent = {
        text: (this.registry?.surname || '')
          + ' ' + (this.registry?.name || ''),
        userId: event.cellData.groups.userId,
        operatorId: this.currentUser.id,
        operatorName: this.users[this.currentUser.id]?.firstname
          + ' ' + this.users[this.currentUser.id]?.lastname,
        startDate: startDate,
        endDate: endDate,
        status: 'temp',
        id: Math.random().toString(36).substr(2, 9),
        deal: null,
        lead: this.currentLead,
        dealDate: null,
        checked: false,
        disabled: false,
        deletable: true
      }

      this.dataSource = [...this.dataSource, this.currentEvent];

      this.currentTime = startDate.toDate();

    },

    deleteAppointment() {
      this.currentTime = this.$moment(this.currentEvent.startDate).toDate();
      this.dataSource = this.$_.filter(this.dataSource, (item) => {
        return item.id !== this.currentEvent.id;
      });
      this.currentEvent = {};
    },


    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.$refs.dxScheduler.instance.repaint();
      }
    },

    setEcho() {
      this.$echo
        .channel(`sales-calendar-updated`)
        .listen('.sales-calendar-updated', (e) => {

          const event = this.parseDealDate(e);
          this.dataSource = this.$_.filter(this.dataSource, (item) => {
            return item.id !== event.id;
          });

          this.dataSource.push(event);
          this.$refs.dxScheduler.instance.repaint();

        })
    },

    async targetAppointment() {
      this.currentDate = this.$moment(this.currentEvent.startDate).toDate();
      await this.scheduler.repaint();
      await this.$nextTick();
      await this.scheduler.scrollTo(this.currentDate);
    }
  },
  beforeDestroy() {
    this.$echo.leave('sales-calendar-updated')
  }
}
</script>

<style scoped lang="scss">
.deleteAppointment {
  top: 5px;
  right: 5px;
}
</style>
