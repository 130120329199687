<template>
  <div v-observe-visibility="(isVisible, entry) => this.showScheduler = isVisible">
    <deal-date-scheduler-legend v-if="enableStatuses"/>
    <deal-date-scheduler-appointment
      @onDelete="deleteDealDateCustom"
      @onUpdate="updateDealDate"
      :other-deal-dates="dealDates"
      :deal-date="dealDateLocal"/>
    <DxScheduler v-if="showScheduler"
      :time-zone="schedulerOptions.timeZone"
                 :height="schedulerHeight"
      :cell-duration="schedulerOptions.cellDuration"
      :start-day-hour="schedulerService.getStartDayHour()"
      :end-day-hour="schedulerService.getEndDayHour()"
      :date-serialization-format="schedulerOptions.dateSerializationFormat"
      :maxAppointmentsPerCell="schedulerOptions.maxAppointmentsPerCell"
      :groups="schedulerOptions.groups"
      :scrolling="schedulerOptions.scrolling"
      :first-day-of-week="schedulerOptions.firstDayOfWeek"
      :views="schedulerOptions.views"
      :current-view="schedulerOptions.currentView"
      :current-date="currentDate"
      :data-source="dataSource"
      :show-current-time-indicator="schedulerOptions.showCurrentTimeIndicator"
      :editing="editing"
      :min="dateMin"
      :max="dateMax"

      :onAppointmentAdded="appointmentAdded"
      :onAppointmentAdding="appointmentAdding"
      :onAppointmentClick="appointmentClick"
      :onAppointmentContextMenu="appointmentContextMenu"
      :onAppointmentDblClick="appointmentDblClick"
      :onAppointmentDeleted="appointmentDeleted"
      :onAppointmentDeleting="appointmentDeleting"
      :onAppointmentFormOpening="appointmentFormOpening"
      :onAppointmentRendered="appointmentRendered"
      :onAppointmentUpdated="appointmentUpdated"
      :onAppointmentUpdating="appointmentUpdating"
      :onCellClick="cellClick"
      :onCellContextMenu="cellContextMenu"
      :onContentReady="onContentReady"
      :onDisposing="disposing"
      :onInizialized="initialized"
      :onOptionChanged="optionChanged"

      appointment-template="AppointmentTemplateSlot"
      ref="dxSchedulerToRecall"
    >
      <DxResource
        :allow-multiple="false"
        :data-source="resourcesData"
        field-expr="userId"
        label="Sale"
      />

      <DxResource
        :allow-multiple="false"
        :data-source="statusData"
        :use-color-as-default="true"
        field-expr="status"
        label="Status"
      />

      <template #AppointmentTemplateSlot="{ data }">
        <div class="position-relative">
          <div>{{ data | optional('appointmentData.text') }}</div>
          <div class="small">
            {{ data | optional('appointmentData.startDate') | time }} -
            {{ data | optional('appointmentData.endDate') | time }}
          </div>
        </div>
      </template>

      <slot name="DxResource"></slot>
    </DxScheduler>
  </div>
</template>

<script>

import {DxResource, DxScheduler} from "devextreme-vue/scheduler";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Modal from "@/components/Modal";
import {DatePicker, Option, Select, TimeSelect} from "element-ui";
import DealDateSchedulerMixin from "@/components/DealDateScheduler/DealDateSchedulerMixin";
import {schedulerService} from "@/util/scheduler-service";
import DealDateSchedulerLegend from "@/components/DealDateScheduler/DealDateSchedulerLegend";
import DealDateSchedulerAppointment from "@/components/DealDateScheduler/DealDateSchedulerAppointment";
import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";

export default {
  name: "DealDateToRecallScheduler",
  components: {
    DealDateSchedulerAppointment,
    DealDateSchedulerLegend,
    ListGroupItemComponent,
    DxScheduler,
    DxResource,
    Modal,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [DealDateSchedulerMixin],
  props: {
    dealDates: {
      type: Array,
      default: () => []
    },
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    }
  },
  computed: {},

  methods: {

    async cellClick(event) {

      this.scheduler.option('disabled', true);
      const update = !!this.dealDateLocal.id;

      if (update) {
        this.dataSource = this.$_.filter(this.dataSource, (item) => {
          return item.id !== this.dealDateLocal.id;
        });
      }

      this.setDealDateLocalFromEventClick(event);

      this.dataSource.push(this.parseDealDate(this.dealDateLocal));
      this.currentTime = event.cellData.startDate;

      try {
        if (update) {
          await this.$api.put(endpoints.DEAL_DATE_UPDATE.replace('{id}', this.dealDateLocal.id), this.dealDateLocal)
        } else {
          this.dealDateLocal.dateable_id = this.deal.id;
          const resp = await this.$api.post(endpoints.DEAL_DATE_STORE_BY_DEAL, this.dealDateLocal);
          this.$_.each(this.dataSource, (item) => {
            if (item.id === this.dealDateLocal.id) {
              item.id = resp?.data?.data?.id;
              item.status = 'confirmed';
              item.disabled = false;
            }
          });
          this.$set(this.dealDateLocal, 'id', resp?.data?.data?.id);
        }
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.scheduler.option('disabled', false);
      }
    },

    getCurrentEvent() {
      return this.dealDateLocal
    },

    deleteDealDateCustom(item) {
      this.deleteDealDate(item)
        .then(() => {
          this.$emit('onDeleteDealDate', item)
        })
        .catch();
    },

    parseDealDate(item) {
      const event = schedulerService.parseDealDate(item);
      event.disabled = this.dealDateLocal.id !== item.id;
      event.status = event.disabled ? 'disabled' : 'confirmed';
      return event;
    },


  },

}
</script>

<style scoped>

</style>
