<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <card class="card shadow" body-classes="standard-card-body">
          <operator-action-container
            direction="column"
            :phones="registry.phones"
            :emails="registry.emails"
            :lead="deal.lead"
            :name="registry | optional('name')"
            :surname="registry | optional('surname')"
            :course-ids="courseIds"
            :appointment-id="appointmentId"
            @onStartCall="leadHistoryKey++"
            @onEmailSent="leadHistoryKey++"
          />
        </card>
      </div>
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.deal') }}</div>
              </div>
            </template>
            <div class="row mb-2">
              <div class="col-12">
                <octo-header-button
                  :buttons="headerButtons"
                  :title="registry.surname + ' ' + registry.name"
                  @onSaveDeal="saveDeal"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <deal-summary
                  ref="dealSummary"
                  :deal="deal"
                  :allowed-status="availableStatuses"
                  :key="`summary-${dealDataKey}`"
                />
              </div>
              <div class="col-md-5">
                <deal-status-container :deal="deal" :key="`dealStatusContainer-${dealDataKey}`"/>
                <deal-history :deal="deal" :key="`dealHistory-${dealDataKey}`"/>
              </div>
            </div>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.lead') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="leadButtons"
              :title="registry.surname + ' ' + registry.name"
              @onOpenLead="$router.push({name: 'leads.archive.show', params: {id: deal.lead.id}})"
            />
            <lead-tab-pane
              v-if="deal.lead"
              :lead="deal.lead"
              :key="`lead-tab-pane-${dealDataKey}`"
            />
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.organizer') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <deal-date-to-recall-scheduler
              :enable-statuses="false"
              :operator="currentUser"
              :deal="deal"
              :deal-dates="deal.dealDates || []"
              :date-min="dateMin"
              :sales="sales"
              :registry="registry"
              @onDeleteDealDate="deleteDealDate"
              @onUpdateDealDate="updateDealDate"
              @onHandleCalendarUpdated="handleCalendarUpdated"
              ref="dealDateToRecallScheduler"
            />
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="registryButtons"
              :title="registry.surname + ' ' + registry.name"
              @onOpenRegistry="$router.push({name: 'registries.show', params: {id: registry.id}})"
            />
            <registry-tab-pane
              :registry="registry"
              :key="`registry-tab-pane-${dealDataKey}`"
            />
          </tab-pane>
        </tabs>
      </div>

    </div>
  </div>


</template>

<script>

import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";
import Registry from "@/models/registry";
import {TabPane, Tabs} from "@/components";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import DealSummaryGeneral from "@/pages/Deals/components/DealSummaryGeneral";
import DealSummary from "@/pages/Deals/components/DealSummary";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import DealDataContainer from "@/pages/Deals/components/DealDataContainer";
import OperatorActionContainer from "@/pages/Operator/component/OperatorActionContainer";
import DealToRecallScheduler from "@/pages/Deals/components/DealToRecallScheduler";
import DealDateToRecallScheduler from "@/components/DealDateScheduler/DealDateToRecallScheduler";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import DealHistory from "@/pages/Deals/components/DealHistory";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import LeadTabPane from "@/components/TabPanels/LeadTabPane";
import {permissionService} from "@/util/permission-service";

export default {
  name: "ShowDealToRecallPage",
  components: {
    LeadTabPane,
    RegistryTabPane,
    DealHistory,
    OctoHeaderButton,
    DealDateToRecallScheduler,
    DealToRecallScheduler,
    OperatorActionContainer,
    DealDataContainer,
    RegistryPersonalData,
    LabelThemeComponent,
    ListGroupItemComponent,
    DealSummary,
    DealSummaryGeneral,
    DealStatusContainer,
    OctoIcon,
    TabPane,
    Tabs,
  },
  data() {
    return {
      dealDataKey: 1,
      leadHistoryKey: 1,
      operatorActionContainerKey: 1,
      deal: this.$_.cloneDeep(Deal),
      registry: this.$_.cloneDeep(Registry),
      endpoints: endpoints,
      dealStatuses: DealStatuses,
      headerButtons: [{
        label: 'save',
        onClick: 'onSaveDeal'
      }],
      permissionService: permissionService,
      appointmentId: null
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.DEAL_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.deal = resp?.data?.data || this.$_.cloneDeep(Deal);
        this.registry = this.deal?.registry || this.$_.cloneDeep(Registry);
        this.dealDataKey++;
        this.leadHistoryKey++;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: "deals.to_recall"});
      })
      .finally(() => {
          this.$fullLoading.hide();
        }
      );

  },

  computed: {
    ...mapGetters({
      users: 'common/users',
      operators: 'common/operators',
      sales: 'common/sales',
      currentUser: 'auth/currentUser',
    }),

    dateMin() {
      return this.$moment().toDate();
    },

    availableStatuses() {
      switch (true) {
        case this.permissionService.isAllowed([permissionService.CALL_CENTER_OPERATOR_ROLE]):
          return [
            this.dealStatuses.pending.value,
            this.dealStatuses.confirmed.value,
            this.dealStatuses.not_confirmed.value,
          ];
        default:
          return [
            this.dealStatuses.pending.value,
            this.dealStatuses.confirmed.value,
            this.dealStatuses.not_valid.value,
            this.dealStatuses.not_interested.value,
          ];
      }
    },

    courseIds() {
      return this.$_.map(this.deal.deal_details, dealDetail => dealDetail.detailable_id)
    },

    registryButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])) {
        buttons.push({label: 'open', onClick: 'onOpenRegistry'});
      }

      return buttons;
    },

    leadButtons() {
      const buttons = [];

      if (
        permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])
        || (
          permissionService.isAllowed([permissionService.CALL_CENTER_OPERATOR_ROLE])
          && this.deal.lead.user_id === this.currentUser.id
        )
      ) {
        buttons.push({label: 'open', onClick: 'onOpenLead'})
      }

      return buttons;
    }
  },
  methods: {
    deleteDealDate(item) {
      this.$set(this.deal, 'dealDates', this.$_.filter(this.deal.dealDates, (dealDate) => {
        return dealDate.id !== item.id
      }))
    },
    updateDealDate(item) {
      this.$set(
        this.deal.dealDates,
        this.$_.findIndex(this.deal.dealDates, (dealDate) => dealDate.id === item.id),
        item
      );

    },
    handleCalendarUpdated(event) {
      this.appointmentId = event.id
    },
    saveDeal: async function () {
      try {
        const newStatus = this.$refs.dealSummary.getStatus();
        const newSubStatus = await this.$refs.dealSummary.getSubStatus();

        if (newStatus === DealStatuses.confirmed.value) {
          const appointment = this.$refs.dealDateToRecallScheduler.getCurrentEvent();
          if (!appointment.id) {
            this.$notify({
              type: 'warning',
              message: this.$t('notifications.cannot_update_deal_to_recall_if_status_not_confirmed')
            });
            return;
          }
        }

        this.$fullLoading.show();
        const resp = await this.$api.put(
          endpoints.DEAL_TO_RECALL_UPDATE.replace('{id}', this.deal.id),
          {
            status: newStatus ? newStatus : this.deal.status,
            sub_status: newStatus ? newSubStatus : this.deal.sub_status,
            text: this.deal.text,
          }
        );

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});

        if (resp?.data?.data?.status !== this.dealStatuses.recall.value) {
          await this.$router.push({name: 'deals.to_recall'});
        } else {
          this.deal = resp?.data?.data || this.$_.cloneDeep(Deal);
          this.registry = this.deal?.registry || this.$_.cloneDeep(Registry);
          this.dealDataKey++;
          this.leadHistoryKey++;
        }

      } catch (e) {

        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

  }
}
</script>

<style scoped lang="scss">

</style>
